import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";

import {
	CoAppDataGridMain,
	CoAppOrganizationSettingsMain,
	CoAppRuleWizardMain,
	CoAppUserProfileMain,
} from "./components/global/styled/global.styled";
import ResponsiveAppBar from "./components/header";
import CoAppNavDrawer from "./components/navigation/CoAppNavDrawer";
import pages from "./constants/pages";

const WithNavRoute = () => {
	const location = useLocation();
	const pathItems = location.pathname.split("/").slice(1);
	const isUserProfile = location.pathname.includes("users") && !isNaN(parseInt(pathItems[2]));
	const isOrganizationSettings = location.pathname === pages.organizationManagement;
	const isRuleEditOrCreate = location.pathname.includes("rules") && !location.pathname.includes("library") && !location.pathname.includes("trash");
	const isSettingsSubPage = pathItems.length === 4;

	let ContentWrapper;

	if (isRuleEditOrCreate && !isSettingsSubPage) {
		ContentWrapper = CoAppRuleWizardMain;
	} else if (isUserProfile && !isSettingsSubPage) {
		ContentWrapper = CoAppUserProfileMain;
	} else if (isOrganizationSettings) {
		ContentWrapper = CoAppOrganizationSettingsMain;
	} else {
		ContentWrapper = CoAppDataGridMain;
	}

	return (
		<>
			<ResponsiveAppBar />
			<CoAppNavDrawer />
			<ContentWrapper>
				<Outlet />
			</ContentWrapper>
		</>
	);
};

export default WithNavRoute;